@import url('https://fonts.googleapis.com/css?family=Fira+Sans|Lato|Lora|Montserrat|Muli|Noto+Sans|Nunito|Nunito+Sans|Poppins|Quicksand|Work+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: left;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-4,
a {
  font-family: 'Quicksand', sans-serif;
}
.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}
.title-bar {
  background-color: #2f7245;
}
.lead {
  font-size: 1.5rem;
}

.font-size-lg {
  font-size: 1.2rem;
}

.display-4 {
  font-size: 3rem;
}
.home {
  background-image: url('./Images/homepage.jpg'),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  /* background: rgba(0,0,0,.5); */
  max-height: 2700px;
  width: 100%;
}
.overlay:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.contact2 {
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
}
.section2 {
  height: 1300px;
  background-image: url('./Images/fetching.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* 
  background-repeat: no-repeat;
  background-size: cover; */
}
.section3 {
  background-image: url('./Images/map.png');
  min-height: 100vh;
  max-height: 190vh;
  background-repeat: no-repeat;
  margin-top: 90px;
  margin-bottom: 0px;
  max-width: 100%;
  height: auto;
}

section.school2 {
  min-height: 153vh;
  max-height: 253vh;
  background-repeat: no-repeat;
}
.waterCrisis {
  background-image: url('./Images/african.jpg');
  height: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
}
.watersection3 {
  background-image: url('./Images/invest.jpg');
  height: 900px;
  background-repeat: no-repeat;
  background-size: 1350px;
}
.watersection5 {
  background-image: url('./Images/background.jpg');
  height: 900px;
  background-repeat: no-repeat;
  background-size: 1350px;
}
.watersection {
  background-image: url('./Images/person1.jpg');
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
}
.watersection1 {
  background-image: url('./Images/background.jpg');
  height: 1300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.col1 {
  background-color: crimson;
  height: 400px;
  width: 1400px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
}
.col2 {
  background-image: url('./Images/learn.jpg');
  height: 400px;
  width: 1400px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
}
.watersection4 {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
}

.homesection2 {
  background-image: url('./Images/water.jpg');
  height: 900px;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutsection1 {
  background-image: url('./Images/washin.jpg');
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutsection2 {
  height: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutsection3 {
  height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
}
.containers {
  padding-top: 100px;
  margin-bottom: 30px;
}
.powered {
  margin-top: 20vh;
}
.headers {
  margin-top: 6rem;
}

.section1 {
  min-height: 600px;
  max-height: 2800vh;
  width: 100%;
  background-color: #4b48e0;
}
.educations {
  min-height: 70vh;
  max-height: 220vh;
}
section.economic {
  min-height: 170vh;
  max-height: 800vh;
  background-color: #eef0f5;
}
section.health1 {
  min-height: 70vh;
  max-height: 90vh;
  margin-top: 0px;
}
.img-rounded {
  width: 200px;
  height: 100px;
}
/* .card{
  width: 80%;
  height: 80%;
  box-shadow: 5px 10px rgb(red, green, blue);
} */
.media {
  background-color: #fff;
  width: 100%;
  height: 120%;
}
.footer {
  min-height: 500px;
  max-height: 1500px;
  background-color: #29293a;
}

.nav-link {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  color: #050000;
}
/* .navbar {
  background-color: none;
  margin-top: 60px;
} */
.grpButton {
  background-color: #af17ce;
}
.inputgrp {
  height: 50px;
  width: 900px;
}
.player {
  margin-top: 0px;
}
.img1 {
  background-image: url('./Images/mech1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 350px;
}
.img2 {
  background-image: url('./Images/mech2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 350px;
}
.img3 {
  background-image: url('./Images/mech3.jpg');
  height: 200px;
  width: 350px;
}
.img4 {
  background-image: url('./Images/child.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  width: 0px;
}
.img5 {
  background-image: url('./Images/cleaning.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  width: 20px;
}
.img6 {
  background-image: url('./Images/sponsor.jpg');
  height: 350px;
  width: 10px;
}
.colBtn {
  background-color: #eef0f5;
  height: 130px;
}

.video-containers {
  min-height: 35vh;
  /* width: 40%; */
  max-height: 880vh;
  margin-bottom: 10px;
}
.video-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 75vh;
  width: 100%;
  max-height: 180vh;
}
.video-container1 iframe,
.video-container1 object,
.video-container1 embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
}
.founderVideo {
  height: 450px;
}
.foundersection1 {
  background-image: url('./Images/book.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

section.mission {
  background-color: #e4f0f7;
  position: relative;
  min-height: 90vh;
  max-height: 920vh;
  overflow: hidden;
}
section.vision1 {
  background-color: #e4f0f7;
  position: relative;
  min-height: 90vh;
  max-height: 1220vh;
  overflow: hidden;
}
section.vision {
  position: relative;
  margin-top: 0px;
  /* background-color: #031b4e; */
  /* height: 10vh; */
  min-height: 55rem;
  /* width: 100%; */
  overflow: hidden;
}
section.missionVision {
  position: relative;
  background-image: url('./Images/Health_Sanitation/mission.jpg');
  min-height: 500px;
  max-height: 2700px;
  overflow: hidden;
  background-repeat: no-repeat;
}

section.compaign {
  background-color: #e4f0f7;
  position: relative;
  min-height: 170vh;
  max-height: 400vh;
  overflow: hidden;
  margin-bottom: 0px;
}
section.team {
  background-color: #e4f0f7;
  position: relative;
  min-height: 170vh;
  max-height: 300vh;
  overflow: hidden;
  margin-bottom: 0px;
}
section.home3 {
  background-color: #e4f0f7;
  position: relative;
  min-height: 60vh;
  max-height: 200vh;
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
}
.home1 {
  background-color: #e4f0f7;
  position: relative;
  min-height: 60vh;
  max-height: 290vh;
  width: 100%;
  border-radius: 0px 0px 130px 230px;
  overflow: hidden;
  margin-top: 0px;
}
section.about3 {
  background-color: #e4f0f7;
  position: relative;
  min-height: 60vh;
  /* height: 80vh; */
  max-height: 290vh;
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
}
section.education2 {
  background-color: #e4f0f7;
  position: relative;
  min-height: 70vh;
  max-height: 220vh;
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
}
.dark {
  background-color: #040405;
}
section.home2 {
  position: relative;
  min-height: 30vh;
  max-height: 280vh;
  width: 100%;
  overflow: hidden;
}
.h3 {
  margin-top: 130px;
}
.h1 {
  margin-top: 330px;
  margin-bottom: 50px;
}
.h2 {
  margin-top: 70px;
}
.breadcrumb {
  background: none !important;
}
.aboutjumbtron {
  position: relative;
  background-image: url('./Images/basic_education/education3.jpg');
  min-height: 250px;
  max-height: 300px;
  top: 0;
  overflow: hidden;
  /* background-repeat: no-repeat;
  background-size: cover; */
}
section.team1 {
  position: relative;
  background-image: url('./Images/teams.jpg');
  min-height: 450px;
  max-height: 1700px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section .img,
section video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
/* 
.overlay {
  background-color: black;
  opacity: 0.6;
  min-height: 500px;
  max-height: 2700px;
  
} */
.overlay2 {
  background-color: #050000;
  opacity: 0.5;
  min-height: 300px;
  max-height: 800px;
}
.overlay1 {
  background-color: #050505;
  opacity: 0.6;
  min-height: 100vh;
  width: 100%;
}

/* section .overlay {
  position: absolute;
  min-height: 500px;
  max-height: 2700px;
} */

section.project {
  position: relative;
  background-image: url('./Images/basic_education/education1.jpg');
  min-height: 450px;
  max-height: 1700px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.health {
  position: relative;
  background-image: url('./Images/Health_Sanitation/health.jpg');
  min-height: 460px;
  max-height: 1700px;
  top: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.sanitation {
  position: relative;
  background-image: url('./Images/karere3.jpg');
  min-height: 460px;
  max-height: 1700px;
  top: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.cooperative {
  position: relative;
  background-image: url('./Images/sewing.jpg');
  min-height: 450px;
  max-height: 1700px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.light {
  background-color: #b9ccdb;
}
.form-control:focus {
  background-color: #b9ccdb;
}
h1.contact {
  color: #fff;
  width: 100%;
  margin: 0px 0px -20px;
  margin-top: 80px;
  font-size: 50px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  z-index: 6;
  text-shadow: 20px 20px 30px rgba(0, 0, 0, 0.15);
}
.contactus1 {
  position: relative;

  min-height: 5px;
  max-height: 5px;
  /* width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover; */
}
.contactus {
  position: relative;
  background-image: url('./Images/basic_education/education2.jpg');
  min-height: 1000px;
  max-height: 2000px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.2);
}

.pad {
  margin-top: 40px;
}
section.about2 {
  position: relative;
  background-image: url('./Images/hand.jpg');
  /* background-color:#9595a7; */
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.school {
  position: relative;
  background-color: #4b48e0;
  min-height: 200px;
  max-height: 1200px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
}
section.achieved {
  position: relative;
  min-height: 100px;
  max-height: 200px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.achievedOverlay {
  background-color: #b9ccdb;
  opacity: 0.7;
  min-height: 300px;
  max-height: 800px;
}
section.donation {
  position: relative;
  min-height: 200px;
  max-height: 800px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #4b48e0;
  /* opacity: 0.4; */
}
section.aboutteam {
  position: relative;
  min-height: 400px;
  max-height: 1900px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.testimony {
  background-color: #e4f0f7;
  position: relative;
  min-height: 700px;
  max-height: 2500px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.colsize {
  width: 600px;
  min-height: 300px;
  max-height: 800px;
}
.boutRow {
  margin-top: 3000px;
}
section.education {
  margin-top: 70px;
  position: relative;
  height: 70vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
section.education1 {
  margin-top: 70px;
  background-color: #e4f0f7;
  position: relative;
  height: 70vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
section.about {
  background-image: url('./Images/status/painted-background.jpg');
  position: relative;
  min-height: 25rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.contact {
  /* height: 30vh; */
  background-color: #e4f0f7;
  max-height: 60vh;
  min-height: 35vh;
}
section.project1 {
  /* height: 30vh; */
  background-color: #e4f0f7;
  max-height: 320vh;
  min-height: 85vh;
}
.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

div.education {
  position: relative;
  background-image: url('./Images/educations.jpg');
  min-height: 15rem;
  max-height: 16rem;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
div.sport {
  position: relative;
  background-image: url('./Images/sport.jpg');
  min-height: 15rem;
  max-height: 16rem;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

div.sanitation {
  position: relative;
  background-image: url('./Images/karere3.jpg');
  min-height: 15rem;
  max-height: 16rem;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
div.cooperative {
  position: relative;
  background-image: url('./Images/cooperative.jpg');
  min-height: 15rem;
  max-height: 16rem;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.hr {
  width: 300px;
  height: 1px;
}
.hr1 {
  height: 1px;
  width: 500px;
}
section.fundraise1 {
  position: relative;
  background-color: #ca2246;
  min-height: 80vh;
  max-height: 300vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.fundraise2 {
  position: relative;
  background-color: #6c60da;
  min-height: 80vh;
  max-height: 200vh;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.fundraise3 {
  position: relative;
  background-color: #07df4f;
  min-height: 80vh;
  max-height: 200vh;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
section.fundraise {
  background-image: url('./Images/basic_education/people.jpg'),
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
  max-height: 1800px;
}
.contactImage {
  background-image: url('./Images/children1.jpg');
  margin-top: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  max-height: 1800px;
}
.contactTitle {
  margin-top: 20px;
  min-height: 90px;
  max-height: 100px;
}
section.contactBox {
  background-image: url('./Images/basic_education/education2.jpg'),
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 700px;
  max-height: 1900px;
}
section.donate {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  max-height: 1700px;
  margin-top: 0px;
}
.testimonial {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  max-height: 1400px;
}
.button {
  width: 300px;
  height: 70px;
}
.button-link {
  color: #4b48e0 !important;
}

.button-link:hover {
  text-decoration: none;
}

.img-div {
  background-color: #03033a;
  min-height: 150px;
  max-height: 1700px;
}

.layer-2 {
  position: absolute;
  display: inline-block;
  z-index: -9;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -6rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #031b4e !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2c4396 !important;
}

.bg-secondary {
  background-color: #e8f0fe !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0c0d !important;
}

.bg-primary-2 {
  background-color: #ff8e88 !important;
}

a.bg-primary-2:hover,
a.bg-primary-2:focus,
button.bg-primary-2:hover,
button.bg-primary-2:focus {
  background-color: #ff5e55 !important;
}

.bg-primary-3 {
  background-color: #1b1f3b !important;
}

a.bg-primary-3:hover,
a.bg-primary-3:focus,
button.bg-primary-3:hover,
button.bg-primary-3:focus {
  background-color: #0b0d18 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-gray {
  background-color: #f3f5f9 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 3px solid #dee2e6 !important;
}

.border-top {
  border-top: 3px solid #dee2e6 !important;
  width: 50%;
  margin-left: 25%;
}

.border-right {
  border-right: 3px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 3px solid #dee2e6 !important;
}

.border-left {
  border-left: 3px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3755be !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-primary-2 {
  border-color: #ff8e88 !important;
}

.border-primary-3 {
  border-color: #1b1f3b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
